
.breadcrumb-content {
  min-height: 246px;
}
.breadcrumb-area {
  background-image: url(../imgs/breadcrumb.jpg);
  background-attachment: scroll;
}
.av-container {
    max-width: 1170px;
  }
.footer-section.footer-one{ 
  background-image: url(../imgs/footer_bg.jpg);
  background-blend-mode: multiply;
}.footer-section.footer-one:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background: #000000 none repeat scroll 0 0;
  z-index: -1;
}
@media (min-width: 992px){.footer-parallax .gradiant-content { 
   margin-bottom: 775px;
}}

.logo img, .mobile-logo img {
  max-width: 500px;
}
.theme-slider {
background: rgba(0, 0, 0, 0.6);
}
.cta-section {
  background-image: url(../imgs/img01.jpg);
  background-attachment: scroll;
}
body{ 
font-size: 15px;
line-height: 1.5;
text-transform: inherit;
font-style: inherit;
}
h1{ 
font-size: px;
line-height: ;
text-transform: inherit;
font-style: inherit;
}
h2{ 
font-size: px;
line-height: ;
text-transform: inherit;
font-style: inherit;
}
h3{ 
font-size: px;
line-height: ;
text-transform: inherit;
font-style: inherit;
}
h4{ 
font-size: px;
line-height: ;
text-transform: inherit;
font-style: inherit;
}
h5{ 
font-size: px;
line-height: ;
text-transform: inherit;
font-style: inherit;
}
h6{ 
font-size: px;
line-height: ;
text-transform: inherit;
font-style: inherit;
}

@media (min-width: 992px){.footer-parallax #content.gradiant-content { 
  margin-bottom: 775px;
}}

.site-title,
.site-description {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}
.breadcrumb-content {
  min-height: 120px;
}
.header.header-seven .navbar-area .menubar .menu-wrap > li > a {
 line-height: unset; 
}

.header.header-seven .navbar-full .widget-wrp .widget {
 max-width: unset; 
}

.logo img {
 max-width: unset; 
}

em {
 color: unset;
 font-weight: unset;
}

.elementor-heading-title {
	padding: 0;
	margin: 0;
	line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
	color: inherit;
	font-size: inherit;
	line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
	font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
	font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
	font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
	font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
	font-size: 59px
}


.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
	background-color: #818a91;
	color: #fff
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
	color: #818a91;
	border: 3px solid;
	background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
	margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
	width: 1em;
	height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
	float: left;
	text-align: center;
	line-height: 1;
	font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
	display: inline-block
}
:root {
  --e-global-color-primary: #7bc113;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
}

body {
  --wp--preset--color--dark--blue: #0071E2;
  
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,rgb(255,206,236) 0%,rgb(152,150,240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg,rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg,rgb(202,248,128) 0%,rgb(113,206,126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
  --wp--preset--duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
  --wp--preset--duotone--grayscale: url(#wp-duotone-grayscale);
  --wp--preset--duotone--purple-yellow: url(#wp-duotone-purple-yellow);
  --wp--preset--duotone--blue-red: url(#wp-duotone-blue-red);
  --wp--preset--duotone--midnight: url(#wp-duotone-midnight);
  --wp--preset--duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
  --wp--preset--duotone--purple-green: url(#wp-duotone-purple-green);
  --wp--preset--duotone--blue-orange: url(#wp-duotone-blue-orange);
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
}
.has-black-color{color: var(--wp--preset--color--black) !important;}
.has-cyan-bluish-gray-color{color: var(--wp--preset--color--cyan-bluish-gray) !important;}
.has-white-color{color: var(--wp--preset--color--white) !important;}
.has-pale-pink-color{color: var(--wp--preset--color--pale-pink) !important;}
.has-vivid-red-color{color: var(--wp--preset--color--vivid-red) !important;}
.has-luminous-vivid-orange-color{color: var(--wp--preset--color--luminous-vivid-orange) !important;}
.has-luminous-vivid-amber-color{color: var(--wp--preset--color--luminous-vivid-amber) !important;}
.has-light-green-cyan-color{color: var(--wp--preset--color--light-green-cyan) !important;}
.has-vivid-green-cyan-color{color: var(--wp--preset--color--vivid-green-cyan) !important;}
.has-pale-cyan-blue-color{color: var(--wp--preset--color--pale-cyan-blue) !important;}
.has-vivid-cyan-blue-color{color: var(--wp--preset--color--vivid-cyan-blue) !important;}
.has-vivid-purple-color{color: var(--wp--preset--color--vivid-purple) !important;}
.has-black-background-color{background-color: var(--wp--preset--color--black) !important;}
.has-cyan-bluish-gray-background-color{background-color: var(--wp--preset--color--cyan-bluish-gray) !important;}
.has-white-background-color{background-color: var(--wp--preset--color--white) !important;}
.has-pale-pink-background-color{background-color: var(--wp--preset--color--pale-pink) !important;}
.has-vivid-red-background-color{background-color: var(--wp--preset--color--vivid-red) !important;}
.has-luminous-vivid-orange-background-color{background-color: var(--wp--preset--color--luminous-vivid-orange) !important;}
.has-luminous-vivid-amber-background-color{background-color: var(--wp--preset--color--luminous-vivid-amber) !important;}
.has-light-green-cyan-background-color{background-color: var(--wp--preset--color--light-green-cyan) !important;}
.has-vivid-green-cyan-background-color{background-color: var(--wp--preset--color--vivid-green-cyan) !important;}
.has-pale-cyan-blue-background-color{background-color: var(--wp--preset--color--pale-cyan-blue) !important;}
.has-vivid-cyan-blue-background-color{background-color: var(--wp--preset--color--vivid-cyan-blue) !important;}
.has-vivid-purple-background-color{background-color: var(--wp--preset--color--vivid-purple) !important;}
.has-black-border-color{border-color: var(--wp--preset--color--black) !important;}
.has-cyan-bluish-gray-border-color{border-color: var(--wp--preset--color--cyan-bluish-gray) !important;}
.has-white-border-color{border-color: var(--wp--preset--color--white) !important;}
.has-pale-pink-border-color{border-color: var(--wp--preset--color--pale-pink) !important;}
.has-vivid-red-border-color{border-color: var(--wp--preset--color--vivid-red) !important;}
.has-luminous-vivid-orange-border-color{border-color: var(--wp--preset--color--luminous-vivid-orange) !important;}
.has-luminous-vivid-amber-border-color{border-color: var(--wp--preset--color--luminous-vivid-amber) !important;}
.has-light-green-cyan-border-color {
	border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
	border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
	border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
	border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
	border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
	background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
	background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
	background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
	background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
	background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
	background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
	background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
	background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
	background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
	background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
	background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
	background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
	font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
	font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
	font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
	font-size: var(--wp--preset--font-size--x-large) !important;
}.ant-switch-checked {
  background: var(--sp-primary);
}
.ant-spin-dot-item {
  background-color: var(--e-global-color-primary);
}
.ant-spin-text {
  color: var(--e-global-color-text);
}
.ant-spin-spinning {
  //display: block;
  //margin-left: auto;
  //margin-right: auto;
  margin-left: 10px;
  margin-right: 10px;

}
h1 {
  color: white;
}
.breadcrumb-heading {

  max-width: unset;
}

.av-columns-area [class*=av-column-] {
  margin-bottom: unset; 
}
.av-py-default {
  padding: 30px 0;
}
.menu_footer {
  padding: 30px;
  width: 150px;
  color: white!important;
  font-weight: bold;
  text-decoration: unset!important;

}
h3 {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family),Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  font-size: unset;
  display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.underline
{
  color: var(--sp-primary);
  text-decoration: underline!important;
  outline: none; 
  font-weight: var(--e-global-typography-primary-font-weight);
}
/*
.ant-btn-primary {
  background:var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}
.ant-btn-primary:hover {
  background:var(--sp-primary2);
  border-color: var(--sp-primary2);
}
*/
button[type=submit]
{
    font-size: unset;
    padding: unset;
    padding-left: 8px;
    padding-right: 8px;
    line-height: unset;
    letter-spacing: unset;
    float:right;
}
.ym-btn-pay {
  background: var(--sp-primary2) !important;
  font-size: unset !important;
  padding: unset !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  line-height: unset !important;
  letter-spacing: unset !important;
  float:right !important;
}
.breadcrumb-list {
  max-width: unset;
}
.breadcrumb-list_ {
  flex-shrink: 0;
  list-style: none;
  //max-width: 55%;
  position: relative;
}
.breadcrumb-list_1 {
  flex-shrink: 0;
  list-style: none;
  width: 100%;
  max-width: 50%;
  padding: 13px 35px;
  position: relative;
  border-radius: 100px;
  margin: 0;
  z-index: 0;  
}

.title {
  color :var(--wp--preset--color--white);
}
.text {
  color :var(--wp--preset--color--white);
}

.menu-right>ul>ul>li, .menu-right>ul>li {
  //color: unset; 
  margin: 10px;
}
.contact-area {
  display: unset;  
}
.contact-icon {
  display: block;  
  text-align: center;
  margin-right: unset;
}

@media only screen and (max-width: 420px)
{
table {
    overflow-x: auto;
    display: block;
}
}

.version
{
font-size: xx-small;
}

@media (max-width: 767px)
{
.breadcrumb-content .breadcrumb-list {
    width: auto;
    
    //text-decoration: underline;
}
}

.elementor a {
  text-decoration: underline ;
}
.breadcrumb-content .breadcrumb-list li a {
  text-decoration: underline;
  font-weight: bold;
}

@media (min-width: 768px)
{
.av-column-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
}
.button_center {
  text-align: left;
}
.button_green{
  margin: 3px;
  --antd-wave-shadow-color: #1890ff;
  background:var(--e-global-color-primary) !important;
  border-color: var(--e-global-color-primary)!important;
  background-color: var(--e-global-color-primary)!important;
}
.button_green:hover  {
  margin: 3px;
  background:var(--sp-primary2) !important;
  border-color: var(--sp-primary2)!important;
  background-color: var(--sp-primary2)!important;
}

.button_blue {
  margin: 3px;
  background: var(--wp--preset--color--vivid-cyan-blue)!important;
  background-color: var(--wp--preset--color--vivid-cyan-blue)!important;
    border-color: var(--wp--preset--color--vivid-cyan-blue)!important;
}

.button_blue:hover {
  margin: 3px;
  background: var(--wp--preset--color--dark--blue)!important;
  background-color: var(--wp--preset--color--dark--blue)!important;
    border-color: var(--wp--preset--color--dark--blue)!important;
}

.button_invisible {
  display: inline-block;
}
